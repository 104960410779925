<template>
  <div class="navbar">
    <img style="width: 100%;display: block;" src="@/assets/bracelet_img/Bitney.png" alt="">
    <div class="navbar-title"
         :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('主菜单')}} <div class="navbar-close"><img @click="goback"
             src="@/assets/bracelet_img/navbar/close.png"
             alt=""></div>
    </div>
    <div class="navbar-text">
      <div class="navbar-text-color"></div>
      <div class="navbar-text-conter" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('一起社交赚钱')}}</div>
      <div class="navbar-text-color"></div>
    </div>
    <div class="m-b">
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="gotoUnityweb">{{$t('首页')}}</div>
      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}" >{{$t('我的NFT收藏')}}</div>
             <!-- @click="goTo('/myNFT')">{{$t('我的NFT收藏')}}</div> -->
      </div>
      <div class="navbar-son">
        <div class="navbar-son-vertical">
          <div class="navbar-vertical-line"></div>
        </div>
        <div class="navbar-son-transverse">
          <!-- <div class="navbar-son-transverse1"></div> -->
          <div class="navbar-son-transverse2" style="height: 85%;"></div>
        </div>
        <div class="navbar-son-box">
          <!-- <div class="navbar-son-text font-size-14"
               :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               @click="goTo('/myNFTbitney')"
               >BITNEY NFT</div> -->
          <div class="navbar-son-text font-size-14"
                style="margin-top:18px;"
                :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                @click="goTo('/myNFT')">META NEBULAS NFT</div>
        </div>
      </div>
      <!-- <div class="navbar-box">
        <div class="navbar-test"
             @click="goTo('/task')">{{$t('任务')}}</div>
      </div> -->
      <div class="navbar-box">
        <van-badge class="navbar-test"
                   :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                   :content="unread_count > 0 ? unread_count : '' "
                   max="99">
          <div @click="goTo('/inbox')" :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('收件箱')}}</div>
        </van-badge>

      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}" >{{$t('市场')}}</div>
             <!-- @click="goTo('/market')">{{$t('市场')}}</div> -->
      </div>
      <div class="navbar-son">
        <div class="navbar-son-vertical">
          <div class="navbar-vertical-line"></div>
        </div>
        <div class="navbar-son-transverse">
          <!-- <div class="navbar-son-transverse1"></div> -->
          <div class="navbar-son-transverse2" style="height: 85%;"></div>
        </div>
        <div class="navbar-son-box">
          <!-- <div class="navbar-son-text font-size-14"
               :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               @click="goTo('/bitneyMarket')" >BITNEY NFT</div> -->
          <div class="navbar-son-text font-size-14"
                style="margin-top:18px;"
                :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                @click="goTo('/market')">META NEBULAS NFT</div>
        </div>
      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="goTo('/ranking')">{{$t('排行榜')}}</div>
      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="goTo('/')">{{$t('开启运动模式')}}</div>
      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('开启社交模式')}}</div>
      </div>
      <div class="navbar-son">
        <div class="navbar-son-vertical">
          <div class="navbar-vertical-line"></div>
        </div>
        <div class="navbar-son-transverse">
          <div class="navbar-son-transverse1"></div>
          <div class="navbar-son-transverse2"></div>
        </div>
        <div class="navbar-son-box">
          <div class="navbar-son-text"
               :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
               @click="goTo('/roomHome')">{{$t('聊天室')}}</div>
          <van-badge style="margin-top:18px;"
                     :content="friendsnum > 0 ? friendsnum : '' "
                     max="99">
            <div class="navbar-son-text"
                 style="margin-top:0;"
                 :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
                 @click="goTo('/friend')">{{$t('好友')}}</div>
          </van-badge>

        </div>
      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="goTo('/myProfile')">{{$t('个人简介')}}</div>
      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="goTo('/report')">{{$t('报告')}}</div>
      </div>
      <div class="navbar-box">
        <div class="navbar-test"
             :class="{'vn-bold-italic': $i18n.locale == 'vi'}"
             @click="goToCourse">{{$t('教程')}}</div>
      </div>
    </div>
  </div>
</template>
<script>

import { getUnread } from '@/api/api'
import { getFriendAddCount, timIsLogin } from '@/utils/tim.js'
export default {

  data () {
    return {
      friendsnum: 0,
      unread_count: 0,
    }
  },

  mounted () {
    this.waitForGlobal()
  },

  methods: {

    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo') && timIsLogin) {
        window.clearTimeout(this.timer);
        // this.getFriendsnum()
        this.getCount();

      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    /* 返回上一页 */
    goback () {
      this.$router.go(-1)
    },
    /* 跳转指定页面 */
    goTo (url) {
      // this.$router.push(url)
      this.$router.replace(url)
    },
    goToCourse(){
      window.location.href = 'https://www.docs.metanebulas.com/tutorial/meta-nebulas-2.0'
    },
    gotoUnityweb () {
      window.location.href = 'https://api.metanebulas.com/unityweb/'
    },
    /* 获取统计 */
    getCount(){
      getFriendAddCount().then(res => {
        this.friendsnum = res
      })
      getUnread().then(res => {
        if(res.code == 0){
          this.unread_count = res.data.num
        }
      })
    },
  },

}
</script>
<style lang="less" scoped>
.navbar {
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/bracelet_img/navbar/bg.png');
  background-size: 100% 100%;
  overflow: hidden;

  .navbar-title {
    width: 100%;
    background-color: #6480f3;
    font-family: REEJI-PinboGB-Flash;
    font-size: 36px;
    letter-spacing: 0px;
    color: #ffffff;
    text-align: center;
    // margin-top: 60px;
    padding: 5px 0;
    position: relative;
    .navbar-close {
      position: absolute;
      top: 0;
      right: 11px;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .navbar-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
    background-color: #2b2b2b;
    font-family: TsangerYuYangT-W03;
    font-size: 12px;
    letter-spacing: 5px;
    padding: 2px 0;
    color: #ffffff;

    .navbar-text-color {
      width: 17px;
      height: 10px;
      background-color: #a7f264;
    }
  }
  .navbar-box {
    margin-top: 16px;
    width: 76%;
    background-color: #2b2b2b;
    border-radius: 0 25px 3px 0px;
    padding: 9px;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .navbar-test {
      width: 92%;
      background-color: #a7f264;
      box-shadow: 2px 2px 1px 0px rgba(19, 19, 19, 0.16);
      border-radius: 3px 20px 3px 20px;
      text-align: center;
      font-family: REEJI-PinboGB-Flash;
      font-size: 20px;
      letter-spacing: 0px;
      color: #2b2b2b;
      padding: 10px 0;
      cursor: pointer;
    }
  }
  .navbar-son {
    display: flex;
    margin-left: 33px;
    .navbar-son-vertical {
      width: 3px;

      .navbar-vertical-line {
        background-color: #000000;
        border: solid 1px #000000;
        height: 85%;
      }
    }
    .navbar-son-transverse {
      width: 93px;
      .navbar-son-transverse1 {
        border-bottom: 3px solid #000000;
        height: 35%;
      }
      .navbar-son-transverse2 {
        border-bottom: 3px solid #000000;
        height: 50%;
      }
    }
  }
  .navbar-son-box {
    .navbar-son-text {
      background-color: #3e3e3e;
      border-radius: 15px 3px 15px 3px;
      border: solid 4px rgba(0, 0, 0, 0.3);
      font-family: REEJI-PinboGB-Flash;
      font-size: 18px;
      letter-spacing: 0px;
      color: #a7f264;
      margin-top: 15px;
      width: 210px;
      text-align: center;
      padding: 10px 0;
    }
    .font-size-14{
      font-size: 14px;
    }
  }
}
.m-b {
  margin-bottom: 100px;
}
/deep/.van-badge {
  font-size: 16px;
  padding: 0 5px;
}
</style>